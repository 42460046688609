import React from 'react';
import { Container, Header } from 'semantic-ui-react';

const siteHeader = props => {
  const { content } = props;

  return (
    <Container fluid className="header border-bottom" textAlign="center">
      <Header as="h3" inverted color="grey" m="5">
        {content}
      </Header>
    </Container>
  );
};

export default siteHeader;
