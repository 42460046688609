const post1 = {
  id: 1,
  directory: 'simple-todo',
  publishDate: '2-15-2019',
  mediaType: 'image',
  catagories: [],
  ImageThumb: '1-tod-list-thumb.png',
  ImageLarge: 'https://picsum.photos/900/400/?image=30',
  shortDescription: 'React Todo list',
  description: 'description of post',
  url: 'http://www.andressbarnes.com',
  body:
    "<h1>React Todo list to brush off the dust..</h1><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. <a href='https://www.andressbarnes.com/labs/todo/' target='_blank'>DEMO here</a> in voluptate velit esse cillum dolore eu fugiat nulla pariatur. <a href='https://github.com/andressbarnes/AB-Todo-List' target='_blank'>Git Hub</a> Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>"
};

export default post1;
