import React from 'react';

const CardContainer = props => {
  const { children, ...custom } = props;
  return (
    <div className="" {...custom}>
      {children}
    </div>
  );
};

export default CardContainer;
