import React from 'react';
import { Segment } from 'semantic-ui-react';
const currentTime = new Date();

const FooterContainer = props => {
  const { bodyContent } = props;

  return (
    <footer className="footer border-top">
      <Segment inverted color="grey" textAlign="center">
        &copy; {currentTime.getFullYear()} {bodyContent}
      </Segment>
    </footer>
  );
};

export default FooterContainer;
