import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './sass/styles.scss';

//components
import Footer from './components/Footer';
import Header from './components/Header';

//views
import Home from './views/Home';

function App() {
  return (
    <BrowserRouter>
      <div className="Site">
        <div className="Site-content">
          <Header content="John Andress Barnes" />
          <Home />
        </div>
      </div>
      <footer className="footer">
        <Footer bodyContent="- andressbarnes.com" />
      </footer>
    </BrowserRouter>
  );
}

export default App;
