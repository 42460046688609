import React from 'react';
import { Grid, Segment } from 'semantic-ui-react';
import CardContainer from './ImageContainer';

const ImageGrid = props => {
  console.log(props);
  const { loading, posts } = props;

  const listItems = posts.map((item, i) => (
    <Grid.Column
      key={i}
      className="fade-in p-2"
      computer={3}
      mobile={6}
      tablet={6}
    >
      <Segment inverted color="grey">
        <CardContainer id={i}>
          <a
            href={`/labs/${item.directory}/`}
            style={{ textDecoration: 'none' }}
          >
            <div className="image">
              <img
                src={require(`../../images/${item.image}`)}
                className="img-fluid"
                alt=""
              />
            </div>
          </a>
          <div className="caption">{item.text}</div>
          <div className="date">{item.date}</div>
        </CardContainer>
      </Segment>
    </Grid.Column>
  ));

  return loading ? (
    <div display="flex" justifyContent="center" mb="3">
      Loading...
    </div>
  ) : (
    <Grid container stackable centered>
      {listItems}
    </Grid>
  );
};

export default ImageGrid;
