import React, { useState, useEffect } from 'react';
import data from './data.js';

//components
import ImageGrid from '../../components/ImageGrid';

const Home = () => {
  const [posts, setPosts] = useState({ isLoading: false }, { data: data });
  useEffect(() => {
    setPosts(posts);
  }, [posts]);
  return (
    <ImageGrid className="card-item" loading={posts.isLoading} posts={data} />
  );
};

export default Home;
