import postData from '../../data/Posts/data/';

const filterSummaryData = () => {
  const data = postData.map(cur => {
    return {
      id: cur.id,
      directory: cur.directory,
      image: cur.ImageThumb,
      date: cur.publishDate,
      text: cur.shortDescription
    };
  });
  return data;
};

const summaryData = filterSummaryData();

export default summaryData;
